<template>
    <div id="about_us">
        <inc_header></inc_header>

        <div class="container">
            <div class="banner"><el-image :src="banner.srcPc" fit="cover"></el-image></div>

            <div class="show">
                <section>
                    <h2>关于我们 About us</h2>
                    <div v-html="info.aboutUs"></div>
                </section>
                <section>
                    <h2>联系我们 Contact us</h2>
                    <div v-html="info.contactUs"></div>
                </section>
            </div>
        </div>

        <inc_footer></inc_footer>
    </div>
</template>

<style scoped>
    .banner { height:480px; }
    .banner .el-image { width:100%; height:100%; }

    .show { margin:60px 0; }
    .show:after { height:0; display:block; content:''; clear:both; visibility:hidden; }
    .show section { width:48%; margin-left:4%; float:left; }
    .show section:first-child { margin-left:0; }
    .show section h2 { margin:0 0 25px 0; font-size:18px; font-weight:400; color:#a00e15; }
    .show section p { text-align:justify; }
    .show section img { max-width:100%; }
</style>

<script>
    import inc_header from "../components/inc_header";
    import inc_footer from "../components/inc_footer";

    export default {
        name: "about_us",
        components: {
            inc_header,
            inc_footer
        },
        data() {
            return {
                banner: {},  // Banner
                info: {}
            }
        },
        created() {
            let that = this;

            // 获取“Banner”
            this.$axios.post(this.$global.config.serverApi+'/banner.php',{ action:"getByCategory", category:"aboutUs" }).then( function(response) {
                if (response.data.complete === true) {
                    that.banner = response.data.banner;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });

            // 获取“关于我们”、“联系我们”
            this.$axios.post(this.$global.config.serverApi+'/info.php',{ action:"get" }).then( function(response) {
                if (response.data.complete === true) {
                    that.info = response.data.info;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });
        }
    }
</script>
